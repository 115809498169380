<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		
		<div class="section2">
			<div class="box">
				<div class="form-box">
					<wd-cell-group class="group" border>
						<wd-input label="您的姓名" label-width="100px" placeholder="请输入您的姓名" align-right v-model="userInfo.name" :disabled="userInfo.name?true:false"/>
						<wd-picker label="您的性别" label-width="100px" placeholder="请选择性别" align-right v-model="userInfo.sex" :disabled="userInfo.sex?true:false" :columns="['男','女']" />
						<wd-input label="年龄" label-width="100px" placeholder="请输入您的年龄" align-right v-model="userInfo.age" />
						<wd-input label="手机号码" label-width="100px" placeholder="请填写您的手机号" align-right v-model="userInfo.tel" disabled />
						<wd-input label="电子邮箱" label-width="100px" placeholder="请填写您的邮箱地址" align-right v-model="userInfo.email" />
						<wd-input v-if="!flage" label="身份证号" label-width="100px" placeholder="请填写您的身份证号" align-right v-model="userInfo.cardid" />
						<wd-input v-if="flage" label="身份证号" label-width="100px" placeholder="请填写您的身份证号" align-right v-model="userInfo.cardid" disabled/>
						<wd-input label="公司名称" label-width="100px" placeholder="请填写您的公司名称" align-right v-model="userInfo.company" />
						<wd-input label="学习证明" label-width="100px" placeholder="请填写您的学习证明" align-right v-model="userInfo.zhengming" />
						<wd-input label="我的证书" label-width="100px" placeholder="请填写您的我的证书" align-right v-model="userInfo.zhengshu" />
						<wd-picker label="最高学历" label-width="100px" placeholder="请选择您的最高学历" align-right v-model="userInfo.edu" :columns="eduList" />
						<wd-input label="微信号" label-width="100px" placeholder="请填写您的微信号" align-right v-model="userInfo.weixin" />
					</wd-cell-group>
				</div>
			</div>
			
			<div class="submit-box">
				<div class="submit" @click="onSubmit">立即修改</div>
			</div>
			
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '个人信息',
				userInfo: {
					name: '',
					sex: '',
					age: '',
					tel: '',
					email: '',
					cardid: '',
					company: '',
					edu: '',
					weixin: ''
				},
				flage:false
			}
		},
		computed: {
			uid() {
				this.$store.commit('saveUid',this.$route.query.uid)
				return this.$route.query.uid
			},
			eduList() {
				return this.$store.state.eduList;
			}
		},
		activated(){
			this.getUserInfo();
		},
		methods: {
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					var data = {
						uid: this.uid
					}
					this.$toast.loading('加载中...');
					const { data:res } = await this.$http.get('/user/userInfo', { params: data });
					this.$toast.close();
					if(this.$store.state.beta){
						console.log('个人信息：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
					}
					var code = res.code;
					if(code == 1) {
						var userInfo = res.data;
						this.$store.commit("saveUserInfo", userInfo);
						this.userInfo = {
							name: userInfo.name,
							sex: userInfo.sex,
							age: userInfo.age,
							tel: userInfo.tel,
							email: userInfo.email,
							cardid: userInfo.cardid,
							company: userInfo.company,
							edu: userInfo.edu,
							weixin: userInfo.weixin

						}
						userInfo.cardid?this.flage = true:this.flage = false;
					} else {
						this.$messageBox.alert(res.data, '温馨提示');
						this.userInfo = {
							name: '',
							sex: '',
							age: '',
							tel: '',
							email: '',
							cardid: '',
							company: '',
							edu: '',
							weixin: ''
						};
					}
				}
			},
			// 提交数据修改用户信息
			async formSubmit(data) {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/user/editUserInfo',data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('个人信息修改：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$store.commit("saveUserInfo", res.data);
					this.$toast.success('修改成功！');
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			onSubmit() {
				var flag = false;
				var	warn = '';
				var nameReg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
				var emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
				var cardidReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
				var ageReg = /^[0-9]*$/;
				var data = this.userInfo;
				data['uid'] = this.uid;
				if (data['cardid'].length>=7&&data['cardid'].length<=15) {
					if(!data['uid']){
					warn = '请先登录！';
				} else if(!nameReg.test(data['name']) || !data['name']){
					warn = '请输入合法的真实姓名！';
				} else if(!data['sex']){
					warn = '请选择您的性别！';
				} else if(!ageReg.test(data['age'])){
					warn = '请输入您的年龄！';
				} else if(!data['email'] || !emailReg.test(data['email'])){
					warn = '请输入合法的电子邮箱地址！';
				} else if(!data['company']){
					warn = '请输入公司名称！';
				} else {
					flag = true;
					this.formSubmit(data);
				}
				if(flag == false) {
					this.$messageBox.alert(warn, '温馨提示');
					return false;
				}
				}else if(!data['uid']){
					warn = '请先登录！';
				} else if(!nameReg.test(data['name']) || !data['name']){
					warn = '请输入合法的真实姓名！';
				} else if(!data['sex']){
					warn = '请选择您的性别！';
				} else if(!ageReg.test(data['age'])){
					warn = '请输入您的年龄！';
				} else if(!data['email'] || !emailReg.test(data['email'])){
					warn = '请输入合法的电子邮箱地址！';
				} else if(!cardidReg.test(data['cardid'])){
					warn = '请输入合法的身份证号！';
				} else if(!data['company']){
					warn = '请输入公司名称！';
				} else {
					flag = true;
					this.formSubmit(data);
				}
				if(flag == false) {
					this.$messageBox.alert(warn, '温馨提示');
					return false;
				}
			}
		},
	};
</script>

<style scoped>
	.section2{
		padding-top: 0.1rem;
		padding-bottom: 0.6rem;
	}
	.section2 .box{
		margin-top: 0rem;
	}
	.section2 .box:first-child{
		margin-top: 0;
	}
	.section2 .box .form-box{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section2 .submit-box{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.6rem;
	}
	.section2 .submit-box .submit{
		width: 5.8rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #c90719;
		font-size: 0.3rem;
		color: #ffffff;
		border-radius: 0.5rem;
	}
</style>